import React from 'react'
import { withSiteData } from '../../components/SiteData'
import AppLayout from "../../components/AppLayout";
import FeatureDisplay, { FeatureDisplayImage, FeatureText } from '../../components/FeatureDisplay'
import FeatureDetailPage from '../../components/FeatureDetailPage'
import PageInfo from '../../components/PageInfo'
import FeatureNav from '../../components/FeatureNav'
import { graphql } from 'gatsby'
import Brand from '../../components/shared/Brand'
import RouterLink from '../../components/shared/RouterLink'

export default withSiteData(({ data, productName }) => (
	<AppLayout>
		<FeatureNav/>
		<FeatureDetailPage
			title="Online Time Tracker App Logs Your Work Hours"
			description={(<>
				<p>
					Logging and managing time log entries are core features of <Brand/>.
					Users can create and edit time logs.
					And by setting up relevant permissions, users in a management role can monitor other
					team members' hours in addition to editing other users' time logs.
					To see a view of your own time, use the convenient "My Time" screen.
				</p>
				<p>
					<Brand/> stores your time logs online. So you'll have immediate access whether your
					team uses the <RouterLink to="/blog/mobile-app/">mobile app</RouterLink>,
					web app, or both.
				</p>
			</>)}
		>
			<PageInfo
				title={"Online Time Tracker - " + productName + " Hours Tracker App"}
				description={productName+ "'s online time tracker app will help you keep time of the hours you work, wherever you are working, any time, day or night."}
			/>

			<section className="tt-limit-content-width">

				<FeatureDisplay
					header="Clock In"
					imgPlacement="right"
					img={<FeatureDisplayImage imgData={data.clockInImg.childImageSharp.gatsbyImageData} alt="Clock In Button"/>}
				>
					<FeatureText
						description={(<>
							<p>Begin tracking time by clocking in.  Once you do, you'll see an indicator in the navigation drawer showing that your time log is in-progress.</p>
							<p>The stop time will be recorded when you clock out.</p>
						</>)}
						small
					/>
				</FeatureDisplay>

				<FeatureDisplay
					header="My Daily Time Logs"
					odd
					imgPlacement="left"
					img={<FeatureDisplayImage imgData={data.myTimeLogsImg.childImageSharp.gatsbyImageData} alt="My Daily Time Logs"/>}
				>
					<FeatureText
						description={(<>
							<p>See today's time logs at a glance and use the day selector to look at different days.</p>
							<p>You'll also find a day visualizer marking the hours you've worked or are currently working.</p>
							<p>See the total time you've logged on your team's projects for the day.</p>
							<p>Add, edit, and delete time logs.</p>
						</>)}
						small
					/>
				</FeatureDisplay>

				<FeatureDisplay
					header="Editing Time Logs"
					imgPlacement="right"
					img={<FeatureDisplayImage imgData={data.editActiveTimeLogImg.childImageSharp.gatsbyImageData} alt="Editing Time Logs"/>}
				>
					<FeatureText
						description={(<>
							<p>The "My Time" screen shows your current activity. Tap a time log to see more details.</p>
							<p>Add some notes about your work, edit the project or user, and alter the start date or time.</p>
							<p>You can also edit the end time as well as revise the other fields even after clocking out.</p>
							<p>Any billable time logs will be included on your <RouterLink to="/features/invoicing/">invoices</RouterLink>.</p>
						</>)}
						small
					/>
				</FeatureDisplay>

				<FeatureDisplay
					header="Editing Dates and Times"
					odd
					imgPlacement="left"
					img={<FeatureDisplayImage imgData={data.timePickerImg.childImageSharp.gatsbyImageData} alt="Edit Dates and Times"/>}
				>
					<FeatureText
						description={(<>
							<p>By clicking the button to the left of the day or time, a popover will present you with options to increment times or use sliders to drag the time.</p>
							<p>You can also type them in manually.  You may either type the numbers, copy and paste, or use the up/down arrow keys on each section.</p>
						</>)}
						small
					/>
				</FeatureDisplay>

			</section>

		</FeatureDetailPage>
	</AppLayout>
));
export const query = graphql`{
  clockInImg: file(relativePath: {eq: "images/features/track-hours/clock-in.png"}) {
    childImageSharp { gatsbyImageData(width: 410, placeholder: BLURRED, layout: CONSTRAINED) }
  }
  editActiveTimeLogImg: file(relativePath: {eq: "images/features/track-hours/edit-active-time-log.png"}) {
    childImageSharp { gatsbyImageData(width: 628, placeholder: BLURRED, layout: CONSTRAINED) }
  }
  myTimeLogsImg: file(relativePath: {eq: "images/features/track-hours/my-time-logs.png"}) {
    childImageSharp { gatsbyImageData(width: 616, placeholder: BLURRED, layout: CONSTRAINED) }
  }
  timePickerImg: file(relativePath: {eq: "images/features/track-hours/time-picker.png"}) {
    childImageSharp { gatsbyImageData(width: 558, placeholder: BLURRED, layout: CONSTRAINED) }
  }
}
`;
